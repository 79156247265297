/** @format */

export enum CustomHttpHeaders {
	X_AUTH_REQUEST = 'X-AUTH-REQUEST',
	X_AUTH_REQUIRED = 'X-AUTH-REQUIRED',
	X_CACHE_MODIFIER = 'X-CACHE-MODIFIER',
	X_LOG_REQUEST = 'X-LOG-REQUEST',
	X_PROXY_REQUEST = 'X-PROXY-REQUEST',
	X_TIMEOUT = 'X-TIMEOUT'
}
